@import "~@angular/material/theming";
@include mat-core();
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

$custom-typography: mat-typography-config(
  $font-family: "Roboto, monospace",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500)
);
.mat-toolbar.mat-primary {
  background: #007bff;
  color: #fff;
}
/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
    50 : #e0e0e0,
    100 : #b3b3b3,
    200 : #808080,
    300 : #4d4d4d,
    400 : #262626,
    500 : #000000,
    600 : #070707,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #a6a6a6,
    A200 : #8c8c8c,
    A400 : #737373,
    A700 : #666666,
    10000 : #05c47b,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,

    )
);

$md-customred: (
    50 : #f3e0e0,
    100 : #e0b3b3,
    200 : #cc8080,
    300 : #b84d4d,
    400 : #a82626,
    500 : #990000,
    600 : #910000,
    700 : #860000,
    800 : #7c0000,
    900 : #6b0000,
    A100 : #ff9a9a,
    A200 : #ff6767,
    A400 : #ff3434,
    A700 : #ff1a1a,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-tvblue: (
    50 : #e1eaf4,
    100 : #b5cbe3,
    200 : #84a9d0,
    300 : #5386bd,
    400 : #2e6caf,
    500 : #0952a1,
    600 : #084b99,
    700 : #06418f,
    800 : #053885,
    900 : #022874,
    A100 : #a2baff,
    A200 : #6f94ff,
    A400 : #3c6eff,
    A700 : #235cff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);





$md-customgrey: (
  50: #e2e2e2,
  100: #b6b6b6,
  200: #868686,
  300: #555556,
  400: #303031,
  500: #0c0c0d,
  600: #0a0a0b,
  700: #080809,
  800: #060607,
  900: #030303,
  A100: #a6a6a6,
  A200: #8c8c8c,
  A400: #737373,
  A700: #666666,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$transportes-vina: (
  50: #0952a1,
  100: #0952a1
);

// $mat-green: (
//   50: #24e74e,
//   100: #0db115
// );

// $mat-red: (
//   50: #dd2323,
//   100: #8b0909
// );
// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($custom-typography);

$custom-primary: mat-palette($md-mcgpalette0, 500);
$custom-accent: mat-palette($md-mcgpalette0, 900);
$custom-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme($custom-primary, $custom-accent, $custom-warn);

@include angular-material-theme($custom-theme);

$custom-primary-progress: mat-palette($mat-blue, 900);
$custom-accent-progress: mat-palette($mat-blue, 100);
$custom-theme-progress: mat-light-theme($custom-primary-progress, $custom-accent-progress);
@include mat-progress-bar-theme($custom-theme-progress);

$custom-primary-badge: mat-palette($mat-red, 500);
$custom-accent-badge: mat-palette($mat-red, 900);
$custom-theme-badge: mat-light-theme($custom-primary-badge, $custom-accent-badge);
@include mat-badge-theme($custom-theme-badge);
